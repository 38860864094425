@font-face {
font-family: 'eina';
src: url(/_next/static/media/b673e699a4e6b990-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'eina Fallback';src: local("Arial");ascent-override: 103.06%;descent-override: 25.07%;line-gap-override: 0.00%;size-adjust: 107.71%
}.__className_1dcf26 {font-family: 'eina', 'eina Fallback';font-weight: 400;font-style: normal
}.__variable_1dcf26 {--font-eina: 'eina', 'eina Fallback'
}

@font-face {
font-family: 'einaSemiBold';
src: url(/_next/static/media/b8b7570f9787e9ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'einaSemiBold Fallback';src: local("Arial");ascent-override: 102.26%;descent-override: 24.87%;line-gap-override: 0.00%;size-adjust: 108.55%
}.__className_bb3067 {font-family: 'einaSemiBold', 'einaSemiBold Fallback';font-weight: 400;font-style: normal
}.__variable_bb3067 {--font-eina-semi-bold: 'einaSemiBold', 'einaSemiBold Fallback'
}

@font-face {
font-family: 'einaBold';
src: url(/_next/static/media/3bbb70ab251bf639-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'einaBold Fallback';src: local("Arial");ascent-override: 101.83%;descent-override: 24.77%;line-gap-override: 0.00%;size-adjust: 109.01%
}.__className_b18140 {font-family: 'einaBold', 'einaBold Fallback';font-weight: 400;font-style: normal
}.__variable_b18140 {--font-eina-bold: 'einaBold', 'einaBold Fallback'
}

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 80px;
  --header-height-tablet: 54px;
  --signup-header-height: 76px;
}

.app {
  font-family: sans-serif;
  font-family: var(--font-eina);
  font-weight: 400;
  font-size: 17px;
}

input {
  font-family: sans-serif;
  font-family: var(--font-eina);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: sans-serif;
  font-family: var(--font-eina);
}

a {
  text-decoration: none;
  color: black;
}

p {
  font-family: sans-serif;
  font-family: var(--font-eina);
  line-height: 150%;
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
}

strong,
em {
  font-family: var(--font-eina-semi-bold);
  font-weight: 400;
}

input {
  outline: none;
}

/* Get rid of annoying autozoom when focus on inputs in ios safari */
select,
textarea,
input,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 17px;
}

/* Hide tracking pixel since it affects the layout */
img[src*="adnxs.com"] {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
          mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

@font-face {
    font-family: 'fontello';
    src: url(/_next/static/media/fontello.900b3eba.eot);
    src: url(/_next/static/media/fontello.900b3eba.eot#iefix) format('embedded-opentype'),
    url(/_next/static/media/fontello.9d00ab72.woff2) format('woff2'),
    url(/_next/static/media/fontello.4545d549.woff) format('woff'),
    url(/_next/static/media/fontello.1d06d5a4.ttf) format('truetype'),
    url(/_next/static/media/fontello.0ad56f67.svg#fontello) format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone-outlined:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-add_circle:before { content: '\e802'; } /* '' */
.icon-add-plus:before { content: '\e803'; } /* '' */
.icon-add:before { content: '\e804'; } /* '' */
.icon-apple:before { content: '\e805'; } /* '' */
.icon-arrow-left:before { content: '\e806'; } /* '' */
.icon-arrow-right:before { content: '\e807'; } /* '' */
.icon-arrows-ccw:before { content: '\e808'; } /* '' */
.icon-article:before { content: '\e809'; } /* '' */
.icon-calendar:before { content: '\e80a'; } /* '' */
.icon-camera_fill:before { content: '\e80b'; } /* '' */
.icon-camera:before { content: '\e80c'; } /* '' */
.icon-cat-insurance:before { content: '\e80d'; } /* '' */
.icon-cat:before { content: '\e80e'; } /* '' */
.icon-chat-lines:before { content: '\e80f'; } /* '' */
.icon-chat:before { content: '\e810'; } /* '' */
.icon-check-badge:before { content: '\e811'; } /* '' */
.icon-checkmark_circle:before { content: '\e812'; } /* '' */
.icon-checkmark_small:before { content: '\e813'; } /* '' */
.icon-checkmark:before { content: '\e814'; } /* '' */
.icon-chevron-down:before { content: '\e815'; } /* '' */
.icon-chevron-left:before { content: '\e816'; } /* '' */
.icon-chevron-right:before { content: '\e817'; } /* '' */
.icon-chevron-up:before { content: '\e818'; } /* '' */
.icon-clock_filled:before { content: '\e819'; } /* '' */
.icon-clock:before { content: '\e81a'; } /* '' */
.icon-close:before { content: '\e81b'; } /* '' */
.icon-course-complete:before { content: '\e81c'; } /* '' */
.icon-credit-card:before { content: '\e81d'; } /* '' */
.icon-creditcard:before { content: '\e81e'; } /* '' */
.icon-delete_btn:before { content: '\e81f'; } /* '' */
.icon-delete_recycle:before { content: '\e820'; } /* '' */
.icon-delete:before { content: '\e821'; } /* '' */
.icon-dental-damage:before { content: '\e822'; } /* '' */
.icon-dental-health:before { content: '\e823'; } /* '' */
.icon-description:before { content: '\e824'; } /* '' */
.icon-document-check:before { content: '\e825'; } /* '' */
.icon-document:before { content: '\e826'; } /* '' */
.icon-dog_check:before { content: '\e827'; } /* '' */
.icon-dog_health:before { content: '\e828'; } /* '' */
.icon-dog_training:before { content: '\e829'; } /* '' */
.icon-dog-food:before { content: '\e82a'; } /* '' */
.icon-dog-health:before { content: '\e82b'; } /* '' */
.icon-dog-house:before { content: '\e82c'; } /* '' */
.icon-dog-insurance:before { content: '\e82d'; } /* '' */
.icon-dog-jump:before { content: '\e82e'; } /* '' */
.icon-dog-lost:before { content: '\e82f'; } /* '' */
.icon-dog-mouth-protection:before { content: '\e830'; } /* '' */
.icon-dog-phone:before { content: '\e831'; } /* '' */
.icon-dog-profile:before { content: '\e832'; } /* '' */
.icon-dog-search:before { content: '\e833'; } /* '' */
.icon-dog:before { content: '\e834'; } /* '' */
.icon-edit-pen:before { content: '\e835'; } /* '' */
.icon-edit:before { content: '\e836'; } /* '' */
.icon-facebook:before { content: '\e837'; } /* '' */
.icon-favorite-filled:before { content: '\e838'; } /* '' */
.icon-favorite-outline:before { content: '\e839'; } /* '' */
.icon-first-prize:before { content: '\e83a'; } /* '' */
.icon-google-black:before { content: '\e83b'; } /* '' */
.icon-google:before { content: '\e83c'; } /* '' */
.icon-guidance:before { content: '\e83d'; } /* '' */
.icon-health-hands:before { content: '\e83e'; } /* '' */
.icon-health:before { content: '\e83f'; } /* '' */
.icon-heart:before { content: '\e840'; } /* '' */
.icon-hourglass:before { content: '\e841'; } /* '' */
.icon-house:before { content: '\e842'; } /* '' */
.icon-identity:before { content: '\e843'; } /* '' */
.icon-information-circle:before { content: '\e844'; } /* '' */
.icon-instagram:before { content: '\e845'; } /* '' */
.icon-insurance-lassie:before { content: '\e846'; } /* '' */
.icon-isurance-1:before { content: '\e847'; } /* '' */
.icon-language:before { content: '\e848'; } /* '' */
.icon-learn-new:before { content: '\e849'; } /* '' */
.icon-learn:before { content: '\e84a'; } /* '' */
.icon-link:before { content: '\e84b'; } /* '' */
.icon-linkedin:before { content: '\e84c'; } /* '' */
.icon-lock:before { content: '\e84d'; } /* '' */
.icon-love-angel:before { content: '\e84e'; } /* '' */
.icon-mail:before { content: '\e84f'; } /* '' */
.icon-medical-scalpel:before { content: '\e850'; } /* '' */
.icon-medication:before { content: '\e851'; } /* '' */
.icon-message-bubble:before { content: '\e852'; } /* '' */
.icon-mix-breed:before { content: '\e853'; } /* '' */
.icon-new:before { content: '\e854'; } /* '' */
.icon-notification:before { content: '\e855'; } /* '' */
.icon-pad-lock:before { content: '\e856'; } /* '' */
.icon-paw:before { content: '\e857'; } /* '' */
.icon-pdf:before { content: '\e858'; } /* '' */
.icon-play:before { content: '\e859'; } /* '' */
.icon-points:before { content: '\e85a'; } /* '' */
.icon-quiz_wrong:before { content: '\e85b'; } /* '' */
.icon-quiz:before { content: '\e85c'; } /* '' */
.icon-radio-selected:before { content: '\e85d'; } /* '' */
.icon-radio:before { content: '\e85e'; } /* '' */
.icon-read-glasses:before { content: '\e85f'; } /* '' */
.icon-redo:before { content: '\e860'; } /* '' */
.icon-referral-new:before { content: '\e861'; } /* '' */
.icon-referral:before { content: '\e862'; } /* '' */
.icon-refferal:before { content: '\e863'; } /* '' */
.icon-reward:before { content: '\e864'; } /* '' */
.icon-scan-radiology:before { content: '\e865'; } /* '' */
.icon-school:before { content: '\e866'; } /* '' */
.icon-search-breed:before { content: '\e867'; } /* '' */
.icon-search:before { content: '\e868'; } /* '' */
.icon-share:before { content: '\e869'; } /* '' */
.icon-shield-heart:before { content: '\e86a'; } /* '' */
.icon-shield-home:before { content: '\e86b'; } /* '' */
.icon-shield-search:before { content: '\e86c'; } /* '' */
.icon-shield-travel:before { content: '\e86d'; } /* '' */
.icon-star:before { content: '\e86e'; } /* '' */
.icon-syringe:before { content: '\e86f'; } /* '' */
.icon-tag:before { content: '\e870'; } /* '' */
.icon-thermometer:before { content: '\e871'; } /* '' */
.icon-trophy:before { content: '\e872'; } /* '' */
.icon-undo:before { content: '\e873'; } /* '' */
.icon-upload-photo:before { content: '\e874'; } /* '' */
.icon-vet-meeting:before { content: '\e875'; } /* '' */
.icon-veterinary_call:before { content: '\e876'; } /* '' */
.icon-video-play:before { content: '\e877'; } /* '' */
.icon-warning-fill:before { content: '\e878'; } /* '' */
.icon-youtube:before { content: '\e879'; } /* '' */





.InnerLayout_content__jqAoh {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 16px;
  max-width: 1080px;
}

@media screen and (max-width: 768px) {
  .InnerLayout_container__hI05I {
    max-width: 500px;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .InnerLayout_divider__ZG3wj {
    height: 35px;
  }
}

/* Added from panel component */
/* navy is info */
/* Colors.blue300 / brand bl */

/* sun is disclaimer */

/* forrest is success */

/* red is error */

.ErrorPage_layout__Ms9bO {
  margin: 0 auto;
  padding-top: 100px;
}

.ErrorPage_fullpageLayout__E3Owy {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background: #FFE7E9;
  display: flex;
}

.ErrorPage_fullpageInnerLayout__ALhAZ {
  max-width: 1000px;
  margin: auto;
  display: flex;
  padding: 40px;
}

.ErrorPage_innerLayout__0sBGv {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ErrorPage_image__q2OW9 {
  max-width: 100%;
  height: auto;
}
.ErrorPage_mobileImage__B1Zz0 {
  margin-top: 40px;
}

.ErrorPage_desktopImage__pgNSE {
  display: none;
}

.ErrorPage_button__FLxgV {
  margin-top: 40px;
}

.ErrorPage_description__yjIvD {
  margin-top: 20px;
  text-align: center;
}

.ErrorPage_title__vnIK8 {
  text-align: center;
}

@media (min-width: 1200px) {
  .ErrorPage_layout__Ms9bO {
    align-items: center;
    flex-direction: row;
  }

  .ErrorPage_innerLayout__0sBGv {
    align-items: flex-start;
    justify-content: center;
  }

  .ErrorPage_desktopImage__pgNSE {
    display: block;
    margin-left: 30px;
    min-width: 375px;
  }

  .ErrorPage_mobileImage__B1Zz0 {
    display: none;
  }

  .ErrorPage_description__yjIvD {
    text-align: left;
  }

  .ErrorPage_title__vnIK8 {
    text-align: left;
  }
}

.typography_display__SsqNw {
  font-size: 60px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_h1__lTvaG {
  font-size: 48px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_h2__nI7Dv {
  font-size: 36px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_h3__iPrbx {
  font-size: 24px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_h4___e2QI {
  font-size: 20px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_h5__Yrshn {
  font-size: 16px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_h6__unt2i {
  font-size: 14px;
  line-height: 120%;
  font-family: var(--font-eina-bold);
  font-weight: 400;
}

.typography_body1__JV59U {
  font-size: 20px;
  line-height: 150%;
  font-family: var(--font-eina);
}

.typography_body2__yalUk {
  font-size: 17px;
  line-height: 150%;
  font-family: var(--font-eina);
}

.typography_body3__hFjue {
  font-size: 14px;
  line-height: 150%;
  font-family: var(--font-eina);
}

.typography_body4__i6UrF {
  font-size: 12px;
  line-height: 135%;
  font-family: var(--font-eina);
}

.typography_truncate__rFNaL {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 768px) {
  .typography_display__SsqNw {
    font-size: 36px;
  }

  .typography_h1__lTvaG {
    font-size: 36px;
  }

  .typography_h2__nI7Dv {
    font-size: 24px;
  }

  .typography_h3__iPrbx {
    font-size: 20px;
  }

  .typography_h4___e2QI {
    font-size: 16px;
  }

  .typography_h5__Yrshn {
    font-size: 14px;
  }

  .typography_h6__unt2i {
    font-size: 14px;
  }
}

.Header_container__3JNoS {
  height: var(--header-height);
  width: 100%;
  padding: 0 20px 0 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms;
  z-index: 214748300;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #E6E6EC;
}

.Header_mobileContainer__1riAB {
  display: none;
}

.Header_desktopContainer__wYhez {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .Header_mobileContainer__1riAB {
    display: flex;
  }

  .Header_desktopContainer__wYhez {
    display: none;
  }
}

.Header_hideHeader__qnYmP {
  top: calc(var(--header-height) * -1);
}

.Header_content__Hg4sZ {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
}

.Header_logo__1FRrW {
  width: 105px;
}

.Header_flex__zPyk0 {
  display: flex;
}

@media (max-width: 768px) {
  .Header_container__3JNoS {
    height: var(--header-height-tablet);
    padding: 0 20px 0 16px;
  }
  .Header_logo__1FRrW {
    width: 88px;
  }

  .Header_hideHeader__qnYmP {
    top: calc(var(--header-height-tablet) * -1);
  }
}

.Header_linkButton___x6VQ {
  border-radius: 999px;
  background-color: #14267B;
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  color: white;
  padding: 12px 18px;
  font-family: var(--font-eina-semi-bold);
  font-size: 17px;
  line-height: 150%;
  text-align: center;
}

.Badge_badge__NDKKM {
  padding: 8px;
  border-radius: 7px;
  line-height: 1;
}

.IconFrame_frame__0fRDy {
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.IconFrame_round__sAkmO {
  border-radius: 50%;
  background-color: #E2EBFF;
}

.MiniSwitch_container__j_2NZ {
  display: flex;
  gap: 12px;
  align-items: center;
}

.MiniSwitch_option__Vn77G {
  all: initial;
  cursor: pointer;
}

.MiniSwitch_switch__B4zKH {
  width: 36px;
  height: 18px;
  border-radius: 24px;
  background-color: #E2EBFF;
  display: flex;
  cursor: pointer;
}

.MiniSwitch_switchRight__3M21f {
  justify-content: flex-end;
}

.MiniSwitch_indicator__jaaMJ {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #14267B;
  margin: 1px;
}

.Accordion_accordion__arZ0T {
}

.Accordion_accordionItem__kA3ie {
  border-top: 1px solid #E6E6EC;
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
}

.Accordion_accordionItem__kA3ie:first-of-type {
  border-top: none;
}

.Accordion_accordionTitle__tVs6S {
  all: initial;
  cursor: pointer;
  display: flex;
  padding: 15px 0;
  align-items: center;
  justify-content: space-between;
}

.Accordion_accordionPanel__XaFdw {
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.Accordion_contentWrapper__oc2tF {
  display: flex;
  flex-direction: column;
}

.Accordion_contentWrapperString__d2Sdc {
  margin-top: -15px;
}

.Accordion_hasIcon__xgWO3 {
  margin: 0 40px 0 56px;
}

.Accordion_iconChevron__Yt4fV {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
}

.Accordion_icon__lkCLQ {
  margin-right: 16px;
}

.Accordion_titleText__PEgjN {
  flex-grow: 1;
}

.Accordion_textContainer__2gvT6 {
  padding: 8px 0;
}

.Accordion_isHidden__lJ2t7 {
  display: none;
}

.Flex_flexRow__kOgOd {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Flex_flexCol__efiyP {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Button_button__ONThi {
  position: relative;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  overflow: hidden;
  outline: none;
}

.Button_variantDefault__g8JRj {
  background-color: #14267B;
}

.Button_variantDefault__g8JRj:hover {
  background-color: #4f5d9c;
}

.Button_variantDanger__T2oSw {
  background-color: #E9283A;
}

button.Button_variantOutlined__2uU0f {
  background-color: transparent;
  border-width: 1px;
  border-color: #14267B;
  border-style: solid;
  color: black;
}

button.Button_variantOutlined__2uU0f:hover {
  border-color: #4f5d9c;
}

.Button_variantOutlined__2uU0f.Button_disabled__C73hA {
  background-color: transparent;
  border-width: 1px;
  border-color: #E6E6EC;
  border-style: solid;
  color: #E6E6EC;
}

.Button_sizeBig__qrvKA {
  cursor: pointer;
  width: 100%;
  border: none;
  min-height: 48px;
}

.Button_small___i4_r {
  min-height: 42px;
}

.Button_big__4AX_W {
  min-height: 56px;
}

.Button_radiusBig__UEt3k {
  border-radius: 100px;
}

.Button_disabled__C73hA {
  background-color: #E6E6EC;
  pointer-events: none;
}

.Button_loadingContainer__E_CtZ {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
}

.Button_iconContainer__YWUnG {
  margin-top: 3px;
}
.Button_golderButtonContainer__iLJu1 {
  background-color: #F4C76E;
  padding: 2px;
  border-radius: 100px;
}

.Button_radiusSquareRounded__A5nJg {
  border-radius: 8px;
}

.MiniBanner_container__3Xxky {
  border: 1px solid #E6E6EC;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.MiniBanner_image__2wcv5 {
  width: 50px;
  height: 50px;
}

.MiniBanner_contentWrapper__UVCN3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}

.MiniBanner_content__r_wp7 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.MiniBanner_badge__xTGva {
  -webkit-margin-start: auto;
          margin-inline-start: auto;
  white-space: nowrap;
}

.MiniBanner_headlineWrapper__qtEMw {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.MiniBanner_subTitle__Pisym {
  margin-bottom: 8px;
}

.MiniBanner_description__M8g_d ul {
  margin: 0 0 0 16px;
  padding: 0;
}

.MiniBanner_description__M8g_d li {
  margin: 0;
  padding: 0 0 8px 8px;
  font-size: 17px;
  line-height: 150%;
}
.MiniBanner_description__M8g_d li::marker {
  color: #B3BCDB;
}

@media screen and (min-width: 768px) {
  .MiniBanner_contentWrapper__UVCN3 {
    flex-direction: row;
  }

  .MiniBanner_image__2wcv5 {
    width: 100px;
    height: 100px;
  }
}

.SelectBox_container__Yt8_p {
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
}

.SelectBox_inputContainer__JWeBz {
  display: flex;
  align-items: center;
  gap: 2px;
}

.SelectBox_input__VO9wg {
  flex: 1 1;
  padding: 8px 8px;
  border: 0;
}

.SelectBox_button__WVzej {
  all: initial;
  cursor: pointer;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectBox_listWrapper__h8DF5 {
  position: relative;
}

.SelectBox_toggleButton__bqnGi {
  border-left: 1px solid #D1D1D1;
}

.SelectBox_listContainer__F7iqX {
  list-style: none;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 12px;
  z-index: 1;
  margin: 0;
}

.SelectBox_list___ICLz {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #E6E6EC;
  padding: 8px;
  margin: 0;
  max-height: 300px;
  overflow: auto;
}

.SelectBox_listItem__pFWiE {
  padding: 12px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.SlidingPanel_wrapper__GeHwi {
  position: relative;
}

.SlidingPanel_container__GLuhr {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 24px 0;
  scroll-behavior: smooth;
}

.SlidingPanel_container__GLuhr::before,
.SlidingPanel_container__GLuhr::after {
  content: "";
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
}

.SlidingPanel_showPrev__9rOSC::before {
  left: 0;
  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.SlidingPanel_showNext__f7V85::after {
  right: 0;
  background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.SlidingPanel_track__oWyxF {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  margin: auto;
  grid-auto-flow: column;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 24px;
}

.SlidingPanel_track__oWyxF > div {
  flex-shrink: 0;
}

.SlidingPanel_button__wqWO4 {
  all: initial;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  border: 1px solid #E6E6EC;
  width: 44px;
  height: 44px;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 30px 8px 0px rgba(204, 204, 204, 0),
    0px 19px 8px 0px rgba(204, 204, 204, 0.01),
    0px 11px 7px 0px rgba(204, 204, 204, 0.05),
    0px 5px 5px 0px rgba(204, 204, 204, 0.09),
    0px 1px 3px 0px rgba(204, 204, 204, 0.1);
  z-index: 1;
}

.SlidingPanel_buttonPrev__BJWTG {
  left: 5px;
}

.SlidingPanel_buttonNext__BLSzY {
  right: 5px;
}

.SlidingPanel_buttonShow__y538M {
  display: flex;
}

.styles_wrapper__2eaXL {
  gap: 8px;
  display: none;
}

@media (min-width: 1200px) {
  .styles_wrapper__2eaXL {
    display: flex;
    align-items: center;
  }
}

.styles_topLevelItem__Xtbr_ {
  all: initial;
  padding: 12px 8px;
  margin: 0;
  display: flex;
  border-radius: 8px;
  align-self: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.styles_menuItems__rOZwP {
  outline: 0;
}

.styles_isExpanded___kJBI {
  color: #14267B;
}

.styles_topLevelItem__Xtbr_:hover,
.styles_topLevelItem__Xtbr_[data-selected] {
  background-color: #F2F2F7;
}

.styles_iconWrapper__Lge50 {
  width: 16px;
  height: 16px;
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
}

.styles_menuList__blqRe {
  background-color: #FFFFFF;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  margin: 0;
  z-index: 2147483021;
}

.styles_menuList__blqRe[hidden] {
  display: none;
}

.styles_menuList__blqRe > .styles_topLevelItem__Xtbr_ {
  width: 100%;
}

.styles_category__bYzW1 {
  color: #7D7D84;
  text-transform: uppercase;
  font-size: 12px;
}

.LanguageSelector_wrapper__lTRTj {
  display: flex;
  position: relative;
  flex-direction: column;
}

.LanguageSelector_toggleButton__2Gi0n {
  width: 100%;
  border: 1px solid #D1D1D1;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.LanguageSelector_valueContainer__jKzPU {
  display: flex;
  align-items: center;
  padding: 12px;
}

.LanguageSelector_icon__0tj2h {
  padding: 8px;
  border-left: 1px solid #D1D1D1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LanguageSelector_listContainer__dxR78 {
  list-style: none;
  padding: 0;
  width: 100%;
  position: absolute;
  bottom: 55px;
  z-index: 1;
  margin: 0;
}

.LanguageSelector_list__U8QTy {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  padding: 12px;
  margin: 0;
  overflow: auto;
}

.LanguageSelector_listItem__BpI6n {
  padding: 12px 8px;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.Hamburger_container__uE_Yp {
  position: relative;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  background: transparent;
}

.Hamburger_bar__2FpFY {
  background: #14267b;
  width: 20px;
  height: 2px;
  position: absolute;
  transition: transform 0.3s;
  border-radius: 2px;
  left: 12px;
}

.Hamburger_barTop__Qiy62 {
  top: 17px;
  transform-origin: 20% 10%;
}

.Hamburger_barBottom__Yf7z_ {
  top: 25px;
  transform-origin: 20% 90%;
}

.Mobile_mobilePanel__AMDua {
  z-index: 2147483009; /* one unit higher than intercom */
  position: fixed;
  right: 0;
  width: 100%;
  top: var(--header-height-tablet);
  height: calc(100vh - var(--header-height-tablet));
  background: white;
  display: none;
  transition: opacity 200ms 0ms ease-in-out;
  padding: 0 20px;
  flex-direction: column;
  opacity: 0;
  overflow: auto;
}

.Mobile_bottomSectionLinks__uZTOq {
  margin-top: auto;
  padding-bottom: 20px;
}

.Mobile_signupButton__qrjwH {
  width: calc(100% - 70px);
  margin-top: 24px;
  min-height: 47px;
}

@media screen and (min-width: 768px) {
  .Mobile_mobilePanel__AMDua {
    top: var(--header-height);
    width: 60%;
    max-height: calc(100vh - var(--header-height));
  }
}

.Mobile_wrapper__2vpei {
  height: calc(100vh - 54px);
  display: flex;
  flex-direction: column;
}

.Mobile_wrapper__2vpei > * {
  border-top: 1px solid #E6E6EC;
}

.Mobile_topLevelLink__SPXdN {
  padding: 22px 0;
  display: block;
}

.Mobile_wrapper__2vpei > *:first-child {
  border: none;
}

.Mobile_wrapper__2vpei > *:last-child {
  border: none;
}

.Mobile_active__Bl6dL {
  display: flex;
  opacity: 1;
}

.Mobile_backdrop__gfUMJ {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  opacity: 0;
}

.Mobile_backdrop__gfUMJ.Mobile_active__Bl6dL {
  display: block;
  z-index: 5;
  opacity: 1;
  transition: opacity 200ms 0ms ease-in-out;
}

.Mobile_menuLink__7weGz {
  padding: 18px 16px;
  display: block;
}

.Mobile_categoryHeader__HMnv3 {
  text-transform: uppercase;
  color: #7D7D84;
  margin-top: 25px;
  display: block;
}

.Footer_grid__qjDt3 {
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 8px;

  gap: 8px;
  margin-bottom: 48px;
}

.Footer_topRow__5Ap3S {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Footer_bottomRow__ax3kB {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 16px;
}

.Footer_wrapper__2L_gp {
  padding: 80px 0;
  background: #F2F2F7;
}

.Footer_container__Z8cUU {
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}

.Footer_column__BQljC {
  margin-bottom: 16px;
}

.Footer_footerInfo__kOytv {
  margin-bottom: 40px;
}

.Footer_topRow__5Ap3S {
  margin-bottom: 48px;
}

.Footer_logo__NsUJK {
  margin-bottom: 40px;
}

.Footer_column__BQljC ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Footer_column__BQljC a {
  color: #535457;
}

.Footer_header__MOL5Q {
  color: #49589B;
  margin-bottom: 16px;
}

.Footer_address__Ndpfs {
  font-style: normal;
  color: #535457;
  margin: 8px 0;
}

.Footer_link__EWWOW {
  padding: 8px 0;
  display: inline-block;
  cursor: pointer;
}

.Footer_linkWithIcon__JcuAI {
  display: flex;
  align-items: center;
}

.Footer_linkIcon__touhO {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

@media (min-width: 768px) {
  .Footer_grid__qjDt3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .Footer_topRow__5Ap3S {
    flex-direction: row;
    align-items: center;
  }

  .Footer_bottomRow__ax3kB {
    flex-direction: row;
    align-items: flex-end;
    gap: 0;
  }

  .Footer_footerInfo__kOytv {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .Footer_logo__NsUJK {
    margin-bottom: 0;
    width: 200px;
    height: auto;
  }

  .Footer_languageSelector__SdgUa {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .Footer_grid__qjDt3 {
    grid-template-columns: 2fr repeat(3, 1fr);
  }

  .Footer_withoutInfo__7d9Ku {
    grid-template-columns: repeat(4, 1fr);
  }

  .Footer_footerInfo__kOytv {
    grid-column-end: 1;
    -webkit-padding-end: 25%;
            padding-inline-end: 25%;
  }

  .Footer_logo__NsUJK {
    margin-bottom: 0;
  }

  .Footer_languageSelector__SdgUa {
    width: 400px;
  }
}

.TuvBadges_tuvBadges__zaaB7 {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .TuvBadges_tuvBadges__zaaB7 img {
    height: 60px;
  }
}

.Layout_container__JuY_k {
  min-height: calc(100vh - var(--header-height));
  overflow: clip;
}

